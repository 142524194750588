(function($){
  //- reCAPTCHA V3

  grecaptcha.ready(function() {
    grecaptcha.execute('6LdVWg0lAAAAAOOEf-pPDXccUBco_wZbKNhKelYU', {action: 'homepage'})
    .then(function(token) {
      document.getElementById('captchaResponse').value = token;
    });
  });

  //- Getting UTMs

  document.addEventListener('DOMContentLoaded', function() {
    // Function to get URL parameters
    function getUTMParameters() {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      var query_string = {};
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        // If the first element of the pair already exists
        if (typeof query_string[pair[0]] === "undefined") {
          query_string[pair[0]] = decodeURIComponent(pair[1]);
          // If duplicated
        } else if (typeof query_string[pair[0]] === "string") {
          var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
          query_string[pair[0]] = arr;
          // If it's already an array
        } else {
          query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
      }
      return query_string;
    }

    // Function for saving UTM parameters in sessionStorage
    function saveUTMInSessionStorage() {
      var utmParams = getUTMParameters();
      for (var key in utmParams) {
        sessionStorage.setItem(key, utmParams[key]);
      }
    }

    // Function for inserting sessionStorage values in inputs
    function insertUTMInInputs() {
      for (var i = 0; i < sessionStorage.length; i++){
        var key = sessionStorage.key(i);
        var value = sessionStorage.getItem(key);
        var input = document.getElementById(key);
        if(input) {
          input.value = value;
        }
      }
    }

    // Calls the function to store UTM parameters in the sessionStorage
    saveUTMInSessionStorage();

    // Calls the function to insert sessionStorage values in the inputs
    insertUTMInInputs();

  });
})(jQuery);